var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "window-container",
      class: { "window-mobile": _vm.isDevice },
    },
    [
      _c("chat-window", {
        attrs: {
          height: _vm.screenHeight,
          theme: "light",
          styles: _vm.styles,
          "current-user-id": _vm.currentUserId,
          "room-id": _vm.roomId,
          "single-room": true,
          rooms: _vm.loadedRooms,
          messages: _vm.messages,
          "messages-loaded": _vm.messagesLoaded,
          "message-actions": _vm.messageActions,
          "rooms-loaded": _vm.roomsLoaded,
          "room-actions": _vm.roomActions,
          "menu-actions": _vm.menuActions,
          "room-message": _vm.roomMessage,
          "show-add-room": _vm.showAddRoom,
          "text-messages": {
            ROOMS_EMPTY:
              "Nenhuma mensagem ou grupo encontrado.\n\nSe increva ou seja convidado para uma turma para ter acesso.",
            ROOM_EMPTY: "Nenhuma conversa selecionada ",
            NEW_MESSAGES: "Novas mensagens!",
            MESSAGE_DELETED: "Mensagem deletada ",
            MESSAGES_EMPTY:
              "Digite uma mensagem para enviar para a equipe de suporte.",
            CONVERSATION_STARTED: "A conversa começou em: ",
            TYPE_MESSAGE: "Digite sua mensagem ",
            SEARCH: "Procurar ",
            IS_ONLINE: "está online ",
            LAST_SEEN: "última conexão ",
            IS_TYPING: "está digitando ... ",
          },
        },
        on: {
          "fetch-more-rooms": _vm.fetchMoreRooms,
          "fetch-messages": _vm.fetchMessages,
          "send-message": _vm.sendMessage,
          "edit-message": _vm.editMessage,
          "delete-message": _vm.deleteMessage,
          "open-file": _vm.openFile,
          "open-user-tag": _vm.openUserTag,
          "room-action-handler": _vm.menuActionHandler,
          "menu-action-handler": _vm.menuActionHandler,
          "send-message-reaction": _vm.sendMessageReaction,
          "typing-message": _vm.typingMessage,
          "toggle-rooms-list": function ($event) {
            return _vm.$emit("show-demo-options", $event.opened)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }