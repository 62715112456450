<template>
  <div class="window-container" :class="{ 'window-mobile': isDevice }">
    <chat-window
      :height="screenHeight"
      theme="light"
      :styles="styles"
      :current-user-id="currentUserId"
      :room-id="roomId"
      :single-room="true"
      :rooms="loadedRooms"
      :messages="messages"
      :messages-loaded="messagesLoaded"
      :message-actions="messageActions"
      :rooms-loaded="roomsLoaded"
      :room-actions="roomActions"
      :menu-actions="menuActions"
      :room-message="roomMessage"
      :show-add-room="showAddRoom"
      @fetch-more-rooms="fetchMoreRooms"
      @fetch-messages="fetchMessages"
      @send-message="sendMessage"
      @edit-message="editMessage"
      @delete-message="deleteMessage"
      @open-file="openFile"
      @open-user-tag="openUserTag"
      @room-action-handler="menuActionHandler"
      @menu-action-handler="menuActionHandler"
      @send-message-reaction="sendMessageReaction"
      @typing-message="typingMessage"
      @toggle-rooms-list="$emit('show-demo-options', $event.opened)"
      :text-messages="{
        ROOMS_EMPTY: 'Nenhuma mensagem ou grupo encontrado.\n\nSe increva ou seja convidado para uma turma para ter acesso.',
        ROOM_EMPTY: 'Nenhuma conversa selecionada ',
        NEW_MESSAGES: 'Novas mensagens!',
        MESSAGE_DELETED: 'Mensagem deletada ',
        MESSAGES_EMPTY: 'Digite uma mensagem para enviar para a equipe de suporte.',
        CONVERSATION_STARTED: 'A conversa começou em: ',
        TYPE_MESSAGE: 'Digite sua mensagem ',
        SEARCH: 'Procurar ',
        IS_ONLINE: 'está online ',
        LAST_SEEN: 'última conexão ',
        IS_TYPING: 'está digitando ... '
      }"
    ></chat-window>
    
  </div>
</template>

<script>
import { parseTimestamp, isSameDay } from './utils/dates'
import ChatWindow from 'educatena-chat'
import 'educatena-chat/dist/vue-advanced-chat.css'
import SvgIcon from './SvgIcon'
import ChatRoomForm from './ChatRoomForm'
import FirebaseSupportChatService from '@/services/firebase/FirebaseSupportChatService'
import User from './firestore/models/User'

export default {
  components: {
    ChatWindow,
    SvgIcon,
    ChatRoomForm
  },

  props: {
    roomId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      rooms: [],
      roomMenuOpened: null,
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: false,
      selectedRoom: null,
      messagesPerPage: 20,
      messages: [],
      messagesLoaded: false,
      messageActions: [],
      roomMessage: '',
      startMessages: null,
      endMessages: null,
      roomsListeners: [],
      listeners: [],
      typingMessageCache: '',
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: '',
      inviteRoomId: null,
      invitedUsername: '',
      removeRoomId: null,
      roomActions: [],
      menuActions: [],
      styles: { container: { borderRadius: '4px' } },
      viewGroupFormModal: false,
      formRoomId: null,
      // ,dbRequestCount: 0
      firebaseChatService: null,
      chatroomData: {}
    }
  },
  beforeMount() {
    this.firebaseChatService = FirebaseSupportChatService.getInstance(this)
    this.firebaseChatService.signInWithCustomToken(this.activeUserInfo.firebase_token).then(() => {
      this.firebaseChatService.initialize()
    })
  },
  mounted() {
    this.updateUserOnlineStatus()

    const actions = [{ name: 'close', title: 'Encerrar suporte'}]

    this.roomActions = actions.filter(action => {
      return this.$acl.hasPermission(action.rule)
    })

    this.menuActions = this.roomActions

    this.messageActions = [{ name: 'replyMessage', title: 'Responder' }]

    setTimeout(() => {
      this.initRoom()
    }, 2000)
  },

  destroyed() {
    this.resetRooms()
  },

  computed: {
    currentUserId() {
      console.log('this.activeUserInfo.id', this.activeUserInfo.id)
      return this.activeUserInfo.id
    },
    isDevice() {
      return window.innerWidth < 500
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    loadedRooms() {
      return this.rooms.slice(0, 1)
    },
    screenHeight() {
      return this.isDevice ? `${window.innerHeight  }px` : 'calc(100vh - 140px)'
    },
    showAddRoom() {
      return this.$acl.hasPermission('chat.create_new_rooms')
    }
  },

  watch: {
    viewGroupFormModal(show) {
      if (!show) {
        this.formRoomId = null
        this.$refs.chatroomform.resetForm()
      }
    }
  },

  methods: {
    async initRoom() {
      
      console.log('this.roomId inicio', this.roomId)
      if (this.roomId && this.firebaseChatService) {
        this.$vs.loading()
        // this.addRoomUser()
        await this.firebaseChatService.fetchRoom(this.roomId).then(room => {
          if (room) {
            console.log('room...', room)

            const users = room.users.map(user => {
              if (user.id.toString() === this.currentUserId.toString()) {
                user.flags.add('current_user')
              }
              return user
            })

            console.log('users2...', users)

            const chatroomData = {
              roomId: room.id,
              roomName: room.roomName,
              description: room.description,
              users: users
            }

            this.rooms.push(chatroomData)
            console.log('this.chatroomData.users', this.chatroomData)
          }
          this.$vs.loading.close()
        })
      } else {

        console.log('creating new room...')

        this.roomId = `${this.currentUserId}_${this.$uuidKey()}`
        console.log('this.roomId', this.roomId)

        const activeUser = this.$store.getters.AppActiveUser
        const user = User.fromDBUser(activeUser)
        user.flags.add('current_user')
        user._id = user.id

        const chatroomData = {
          roomId: this.roomId,
          roomName: 'Ajuda',
          users: [user]
        }

        await this.firebaseChatService.storeRoom({
          roomId: this.roomId,
          roomName: 'Ajuda',
          description: `Suporte ${this.activeUserInfo.name}`,
          users: chatroomData.users,
          type: {
            name: 'support',
            value: `${this.currentUserId}`
          }
        }, this.roomId)

        this.rooms.push(chatroomData)
      }

    },
    dlog(...things) {
      this.devLog('chat-container', ...things)
    },

    closeRoomMenu() {
      this.roomMenuOpened = null
    },

    resetRooms() {
      this.loadingRooms = true
      this.loadingLastMessageByRoom = 0
      this.roomsLoadedCount = 1
      this.rooms = []
      this.roomsLoaded = true
      this.startRooms = null
      this.endRooms = null
      this.roomsListeners.forEach(listener => listener())
      this.roomsListeners = []
      this.resetMessages()
    },

    resetMessages() {
      this.messages = []
      this.messagesLoaded = false
      this.startMessages = null
      this.endMessages = null
      this.listeners.forEach(listener => listener())
      this.listeners = []
    },

    listenLastMessage(room) {
      if (!room) return
      const listener = this.firebaseChatService.getMessagesRef(room.roomId)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .onSnapshot(messages => {
          // this.incrementDbCounter('Listen Last Room Message', messages.size)
          messages.forEach(message => {
            const lastMessage = this.formatLastMessage(message.data())
            const roomIndex = this.rooms.findIndex(
              r => room.roomId === r.roomId
            )
            this.rooms[roomIndex].lastMessage = lastMessage
            this.rooms = [...this.rooms]
          })
          if (this.loadingLastMessageByRoom < this.rooms.length) {
            this.loadingLastMessageByRoom++

            if (this.loadingLastMessageByRoom === this.rooms.length) {
              this.loadingRooms = false
              this.roomsLoadedCount = this.rooms.length
            }
          }
        })

      this.roomsListeners.push(listener)
    },

    formatLastMessage(message) {
      if (!message.timestamp) return

      let content = message.content
      if (message.file) content = `${message.file.name}.${message.file.extension ||
          message.file.type}`

      return {
        ...message,
        ...{
          content,
          timestamp: this.formatTimestamp(
            new Date(message.timestamp.seconds * 1000),
            message.timestamp
          ),
          distributed: true,
          seen: message.sender_id === this.currentUserId ? message.seen : null,
          new:
            message.sender_id !== this.currentUserId &&
            (!message.seen || !message.seen[this.currentUserId])
        }
      }
    },

    formatTimestamp(date, timestamp) {
      const timestampFormat = isSameDay(date, new Date()) ? 'HH:mm' : 'DD/MM/YY'
      const result = parseTimestamp(timestamp, timestampFormat)
      return timestampFormat === 'HH:mm' ? `Today, ${result}` : result
    },

    fetchMessages({ room, options = {} }) {
      this.$emit('show-demo-options', false)
      if (!room) return

      if (options.reset) this.resetMessages()

      if (this.endMessages && !this.startMessages) return (this.messagesLoaded = true)

      const ref = this.firebaseChatService.getMessagesRef(room.roomId)

      let query = ref.orderBy('timestamp', 'desc').limit(this.messagesPerPage)

      if (this.startMessages) query = query.startAfter(this.startMessages)

      this.selectedRoom = room.roomId

      query.get().then(messages => {
        // this.incrementDbCounter('Fetch Room Messages', messages.size)
        if (this.selectedRoom !== room.roomId) return

        if (messages.empty) this.messagesLoaded = true

        if (this.startMessages) this.endMessages = this.startMessages
        this.startMessages = messages.docs[messages.docs.length - 1]

        let listenerQuery = ref.orderBy('timestamp')

        if (this.startMessages) listenerQuery = listenerQuery.startAfter(this.startMessages)
        if (this.endMessages) listenerQuery = listenerQuery.endAt(this.endMessages)

        if (options.reset) this.messages = []

        messages.forEach(message => {
          const formattedMessage = this.formatMessage(room, message)
          this.messages.unshift(formattedMessage)
        })

        const listener = listenerQuery.onSnapshot(snapshots => {
          // this.incrementDbCounter('Listen Room Messages', snapshots.size)
          this.listenMessages(snapshots, room)
        })
        this.listeners.push(listener)
      })
    },

    listenMessages(messages, room) {
      messages.forEach(message => {
        const formattedMessage = this.formatMessage(room, message)
        const messageIndex = this.messages.findIndex(m => m._id === message.id)

        if (messageIndex === -1) {
          this.messages = this.messages.concat([formattedMessage])
        } else {
          this.$set(this.messages, messageIndex, formattedMessage)
        }

        this.markMessagesSeen(room, message)
      })
    },

    markMessagesSeen(room, message) {
      if (
        message.data().sender_id !== this.currentUserId &&
        (!message.data().seen || !message.data().seen[this.currentUserId])
      ) {
        this.firebaseChatService.getMessagesRef(room.roomId)
          .doc(message.id)
          .update({
            [`seen.${this.currentUserId}`]: new Date()
          })
      }
    },

    formatMessage(room, message) {
      const senderUser = room.users.find(
        user => message.data().sender_id === user._id
      )

      const { sender_id, timestamp } = message.data()

      return {
        ...message.data(),
        ...{
          senderId: sender_id,
          _id: message.id,
          seconds: timestamp.seconds,
          timestamp: parseTimestamp(timestamp, 'HH:mm'),
          date: parseTimestamp(timestamp, 'DD MMMM YYYY'),
          username: senderUser ? senderUser.username : null,
          // avatar: senderUser ? senderUser.avatar : null,
          distributed: true
        }
      }
    },

    async sendMessage({ content, roomId, file, replyMessage }) {
      const message = {
        sender_id: this.currentUserId,
        content,
        timestamp: new Date()
      }

      if (file) {
        message.file = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.localUrl
        }
        if (file.audio) {
          message.file.audio = true
          message.file.duration = file.duration
        }
      }

      if (replyMessage) {
        message.replyMessage = {
          _id: replyMessage._id,
          content: replyMessage.content,
          sender_id: replyMessage.senderId
        }

        if (replyMessage.file) {
          message.replyMessage.file = replyMessage.file
        }
      }

      const { id } = await this.firebaseChatService.getMessagesRef(roomId).add(message)

      if (file) this.uploadFile({ file, messageId: id, roomId })

      this.firebaseChatService.roomsRef.doc(roomId).update({ lastUpdated: new Date() })
    },

    openFile({ message }) {
      window.open(message.file.url, '_blank')
    },

    async openUserTag({ user }) {
      let roomId = null

      this.rooms.forEach(room => {
        if (room.users.length === 2) {
          const userId1 = room.users[0]._id
          const userId2 = room.users[1]._id
          if (
            (userId1 === user._id || userId1 === this.currentUserId) &&
            (userId2 === user._id || userId2 === this.currentUserId)
          ) {
            roomId = room.roomId
          }
        }
      })

      if (roomId) return (this.roomId = roomId)

      const query1 = await this.firebaseChatService.roomsRef
        .where('users', '==', [this.currentUserId, user._id])
        .get()

      if (!query1.empty) {
        return this.loadRoom(query1)
      }

      const query2 = await this.firebaseChatService.roomsRef
        .where('users', '==', [user._id, this.currentUserId])
        .get()

      if (!query2.empty) {
        return this.loadRoom(query2)
      }

      const room = await this.firebaseChatService.roomsRef.add({
        users: [user._id, this.currentUserId],
        lastUpdated: new Date()
      })

      this.roomId = room.id
    },

    async loadRoom(query) {
      query.forEach(async room => {
        if (this.loadingRooms) return
        await this.firebaseChatService.roomsRef.doc(room.id).update({ lastUpdated: new Date() })
        this.roomId = room.id
      })
    },

    async editMessage({ messageId, newContent, roomId, file }) {
      const newMessage = { edited: new Date() }
      newMessage.content = newContent

      if (file) {
        newMessage.file = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl
        }
        if (file.audio) {
          newMessage.file.audio = true
          newMessage.file.duration = file.duration
        }
      } else {
        newMessage.file = this.firebaseChatService.deleteDbField
      }

      await this.firebaseChatService.getMessagesRef(roomId)
        .doc(messageId)
        .update(newMessage)

      if (file) this.uploadFile({ file, messageId, roomId })
    },

    async deleteMessage({ message, roomId }) {
      await this.firebaseChatService.getMessagesRef(roomId)
        .doc(message._id)
        .update({ deleted: new Date() })

      const { file } = message

      if (file) {
        const deleteFileRef = this.firebaseChatService.filesRef
          .child(this.currentUserId)
          .child(message._id)
          .child(`${file.name}.${file.extension || file.type}`)

        await deleteFileRef.delete()
      }
    },

    async uploadFile({ file, messageId, roomId }) {
      let type = file.extension || file.type
      if (type === 'svg' || type === 'pdf') {
        type = file.type
      }

      const uploadFileRef = this.firebaseChatService.filesRef
        .child(this.currentUserId.toString())
        .child(messageId)
        .child(`${file.name}.${type}`)

      await uploadFileRef.put(file.blob, { contentType: type })
      const url = await uploadFileRef.getDownloadURL()
      await this.firebaseChatService.getMessagesRef(roomId)
        .doc(messageId)
        .update({
          ['file.url']: url
        })
    },

    menuActionHandler({ action, roomId }) {
      let result = null // This is only used when deleting the room.
      switch (action.name) {
      case 'updateRoom':
        this.formRoomId = roomId
        return this.showRoomForm(roomId)
      case 'deleteRoom':
        this.$utils.browser.confirmationDialog(this.$vs,
          'Tem certeza que quer excluir esse grupo de chat?',
          () => {
            result = this.deleteRoom(roomId)
          }
        )
        return result
      }
    },

    async sendMessageReaction({ reaction, remove, messageId, roomId }) {
      const dbAction = remove
        ? this.firebaseChatService.firebase.firestore.FieldValue.arrayRemove(this.currentUserId)
        : this.firebaseChatService.firebase.firestore.FieldValue.arrayUnion(this.currentUserId)

      await this.firebaseChatService.getMessagesRef(roomId)
        .doc(messageId)
        .update({
          [`reactions.${reaction.name}`]: dbAction
        })
    },

    typingMessage({ message, roomId }) {
      if (message && message.length > 1) {
        return (this.typingMessageCache = message)
      }

      if (message && message.length === 1 && this.typingMessageCache) {
        return (this.typingMessageCache = message)
      }

      this.typingMessageCache = message

      const dbAction = message
        ? this.firebaseChatService.firebase.firestore.FieldValue.arrayUnion(this.currentUserId)
        : this.firebaseChatService.firebase.firestore.FieldValue.arrayRemove(this.currentUserId)

      this.firebaseChatService.roomsRef.doc(roomId).update({
        typingUsers: dbAction
      })
    },

    async listenRooms(query) {
      const listener = query.onSnapshot(rooms => {
        // this.incrementDbCounter('Listen Rooms Typing Users', rooms.size)
        rooms.forEach(room => {
          const foundRoom = this.rooms.find(r => r.roomId === room.id)
          if (foundRoom) {
            foundRoom.typingUsers = room.data().typingUsers
            foundRoom.index = room.data().lastUpdated.seconds
          }
        })
      })
      this.roomsListeners.push(listener)
    },

    updateUserOnlineStatus() {
      const userStatusRef = this.firebaseChatService.firebase
        .database()
        .ref(`/status/${  this.currentUserId}`)

      const isOfflineData = {
        state: 'offline',
        lastChanged: this.firebaseChatService.firebase.database.ServerValue.TIMESTAMP
      }

      const isOnlineData = {
        state: 'online',
        lastChanged: this.firebaseChatService.firebase.database.ServerValue.TIMESTAMP
      }

      this.firebaseChatService.firebase
        .database()
        .ref('.info/connected')
        .on('value', snapshot => {
          if (snapshot.val() === false) return

          userStatusRef
            .onDisconnect()
            .set(isOfflineData)
            .then(() => {
              userStatusRef.set(isOnlineData)
            })
        })
    },

    listenUsersOnlineStatus(rooms) {
      rooms.map(room => {
        room.users.map(user => {
          const listener = this.firebaseChatService.firebase
            .database()
            .ref(`/status/${  user._id}`)
            .on('value', snapshot => {
              if (!snapshot || !snapshot.val()) return

              const lastChanged = this.formatTimestamp(
                new Date(snapshot.val().lastChanged),
                new Date(snapshot.val().lastChanged)
              )

              user.status = { ...snapshot.val(), lastChanged }

              const roomIndex = this.rooms.findIndex(
                r => room.roomId === r.roomId
              )

              this.$set(this.rooms, roomIndex, room)
            })
          this.roomsListeners.push(listener)
        })
      })
    },

    async addRoomUser() {
      this.disableForm = true

      await this.firebaseChatService.usersRef.doc(this.currentUserId).create(
        {
          _id: this.currentUserId,
          username: this.activeUserInfo.name 
        }
      )

      await this.firebaseChatService.roomsRef
        .doc(this.roomId)
        .update({ users: this.firebaseChatService.firebase.firestore.FieldValue.arrayUnion(this.currentUserId) })

      this.inviteRoomId = null
      this.invitedUsername = ''
    },

    /*
    ,incrementDbCounter(type, size) {
      size = size || 1
      this.dbRequestCount += size
      console.log(type, size)
    }
    */
  }
}
</script>

<style lang="scss" scoped>
.window-container {
  width: 450px;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}

.window-mobile {
  form {
    padding: 0 10px 10px;
  }
}

form {
  padding-bottom: 20px;
}

input {
  padding: 5px;
  width: 140px;
  height: 21px;
  border-radius: 4px;
  border: 1px solid #d2d6da;
  outline: none;
  font-size: 14px;
  vertical-align: middle;

  &::placeholder {
    color: #9ca6af;
  }
}

button {
  background: #1976d2;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 10px;
  border: none;
  font-size: 14px;
  transition: 0.3s;
  vertical-align: middle;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    cursor: initial;
    background: #c6c9cc;
    opacity: 0.6;
  }
}

.button-cancel {
  color: #a8aeb3;
  background: none;
  margin-left: 5px;
}

select {
  vertical-align: middle;
  height: 33px;
  width: 152px;
  font-size: 13px;
  margin: 0 !important;
}
</style>
